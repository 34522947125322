import { ThingEventSnapshot } from '@eagle/core-data-types';
import { CacheDataTypes, getThreshold, useCustomRoutes } from '@eagle/react-common';
import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ThingEventInvestigateButton: FC<{ event: ThingEventSnapshot; filters?: string[] }> = ({ event, filters }) => {
  const { t } = useTranslation(['track']);
  const date = DateTime.fromJSDate(event.timing.received);
  const threshold = getThreshold(event.eventTypeId);
  const navigate = useNavigate();
  const { history: customRoutesHistory } = useCustomRoutes();

  const onClick = (): void => {
    navigate(`/${customRoutesHistory}`,
      {
        state: {
          dateEnd: date.plus({ minutes: threshold.end }).toJSDate(),
          dateStart: date.minus({ minutes: threshold.start }).toJSDate(),
          entityId: event.thingId,
          entityItemFields: {
            entity: CacheDataTypes.THING,
            entityType: CacheDataTypes.THING_TYPE,
            entityTypeKey: 'thingTypeId',
          },
          filters: filters ?? [],
          focused: true,
        },
      },
    );
  };

  return (
    <Box
      sx={{ mx: 1, mb: 1, justifyContent: 'flex-start' }}
    >
      <Button
        onClick={onClick}
        size="small"
        variant="text"
      >
        {t('common:component.events.thing-event.labels.table.more-details')}
      </Button>
    </Box>
  );
};
